import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import styled, { css, keyframes } from 'styled-components';
import City from './nexceris-city-art.png';
import Chevron from './icon-chevron.png';
import LANDMARKS from './DATA';

const brandGreen = 'rgb(147, 200, 65)';

const initialContent = 'Home';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  overflow: visible;
  position: relative;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  * {
    outline: 0;
  }
`;

const Map = styled.svg`
  padding: 0;
  width: 100%;

  /* @media (min-width: 1024px) {
    height: auto;
    min-width: 1024px;
    max-width: 3406px;
    width: 100%;
  } */
`;

const pulseAnimation = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: .4;
  }
  100% {
    opacity: 0;
  }
`;

const selectorStyles = css`
  animation: ${pulseAnimation} 2s infinite;
  cursor: pointer;
  fill: white;
  opacity: 0;
  transition: all 0.3s ease-in-out;

  &:hover {
    animation: none;
    opacity: 0.6;
  }

  &#home {
    fill: black;
  }
`;

const MapSelector = styled.polygon`
  ${selectorStyles}
`;

const HomeSelector = styled.rect`
  ${selectorStyles}
`;

const Content = styled.div`
  display: none;
  flex-direction: column;
  margin: auto;
  max-width: 500px;
  position: relative;

  &.current {
    display: flex;
  }
`;

const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
`;

const Icon = styled.img`
  margin-left: -60px;
  max-height: 60px;
  width: 60px;
`;

const Title = styled.h2`
  color: ${brandGreen};
  margin-left: 20px;
`;

const Description = styled.div`
  margin: 0 auto;
  width: 80%;
`;

const Link = styled.a`
  background: ${brandGreen} none repeat scroll 0% 0% / auto padding-box
    border-box;
  border-radius: 10px;
  box-shadow: none;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: grid;
  fill: rgb(255, 255, 255);
  font: normal normal normal normal 16px/16px 'Open Sans', sans-serif;
  height: 49px;
  margin: 30px auto 0;
  place-items: center;
  text-decoration: underline;
  transition: all 0.3s ease 0s;
  width: 200px;
`;

const navButtonStyles = css`
  background: white;
  border: 0;
  cursor: pointer;
  height: 40px;
  margin-top: 24px;
  width: 40px;

  img {
    margin: 0;
    padding: 0;
    width: 25px;
  }
`;

const PreviousButton = styled.button`
  ${navButtonStyles}
`;

const NextButton = styled.button`
  ${navButtonStyles}
  transform: rotate(180deg);
`;

function App() {
  const [content, setContent] = useState(initialContent);

  const contentClass = (content) => {
    switch (content) {
      case 'Utilities (power lines)':
        return 'batteries';
      case 'Homes':
        return 'sofc';
      case 'Truck (emissions)':
        return 'sensors';
      case 'EV':
        return 'ev';
      case 'Home':
        return 'home';
      case 'Solar plus energy storage (battery)':
        return 'li-ion';
      case 'Oil and Gas Reforming Sation':
        return 'catalyst';
      case 'Wind plus energy storage (H2)':
        return 'soec';
      case 'Commercial space with Nexceris truck at the loading dock':
        return 'materials';
      default:
        return 'none';
    }
  };

  return (
    <Wrapper className={contentClass(content)}>
      <Map id="city" version="1.1" viewBox="0 0 3406 2541">
        <image width="3406" height="2541" xlinkHref={City} />
        {LANDMARKS.map((landmark, index) => (
          <MapSelector
            data-tip={landmark.technology}
            data-tip-disable={(content !== '') | (content !== 'none')}
            id={landmark.id}
            onClick={() => setContent(landmark.id)}
            key={index}
            points={landmark.points}
            transform={landmark.transform}
          />
        ))}
        <HomeSelector
          data-tip="LEVER"
          onClick={() => setContent('Home')}
          x="1400"
          y="950"
          height="400px"
          width="420px"
        />
      </Map>
      {LANDMARKS.map((landmark, index) => (
        <Content
          className={`${landmark.id === content ? 'current' : ''}`}
          key={index}
        >
          <TitleWrapper>
            <Icon alt={landmark.id} src={landmark.icon} />
            <Title>{landmark.technology}</Title>
          </TitleWrapper>
          <Description>
            <p>{landmark.description}</p>
          </Description>
          <TitleWrapper>
            {index + 1 < LANDMARKS.length && (
              <PreviousButton
                onClick={() => setContent(LANDMARKS[index + 1].id)}
              >
                <img alt="previous" src={Chevron} />
              </PreviousButton>
            )}
            <Link href={landmark.link}>Learn More</Link>
            {index > 0 && (
              <NextButton onClick={() => setContent(LANDMARKS[index - 1].id)}>
                <img alt="next" src={Chevron} />
              </NextButton>
            )}
          </TitleWrapper>
        </Content>
      ))}
      <ReactTooltip />
    </Wrapper>
  );
}

export default App;

import IconBattery from './IconBattery.svg';
import IconCatalysts from './IconCatalysts.svg';
import IconLionTamer from './IconLionTamer.svg';
import IconMaterials from './IconMaterials.svg';
import IconSensors from './IconSensors.svg';
import IconSOEC from './IconSOEC.svg';
import IconSOFC from './IconSOFC.svg';
import Logo from './logo.png';

const LANDMARKS = [
  {
    order: '1',
    technology: 'Batteries',
    id: 'Utilities (power lines)',
    icon: IconBattery,
    description:
      'Grid modernization efforts are currently forming the future of our energy grid.  Nexceris is a developer and supplier of solutions that make energy production safer, more efficient, and environmentally responsible.',
    link: 'https://nexceris.com/nexceris-products/battery-materials/',
    points: '254 1929 352 2037 156 2037',
    transform:
      'translate(254.000000, 1983.000000) rotate(-270.000000) translate(-254.000000, -1983.000000)',
  },
  {
    order: '2',
    technology: 'SOFC',
    id: 'Homes',
    icon: IconSOFC,
    description:
      'Micro-CHP systems for residential use are gaining traction since they use fuel cells to produce both heat and power.  Nexceris has a deep heritage in Solid Oxide Fuel Cell (SOFC) development and has established itself as a world leader in the field.',
    link: 'https://nexceris.com/solutions/solid-oxide-fuel-cells/',
    points: '3240 1513 3338 1621 3142 1621',
    transform:
      'translate(3240.000000, 1567.000000) rotate(-270.000000) translate(-3240.000000, -1567.000000)',
  },
  {
    order: '3',
    technology: 'Sensors',
    id: 'Truck (emissions)',
    icon: IconSensors,
    description:
      'Strict limits are being placed on nitrogen oxide emissions as emission laws and regulations are becoming increasingly stringent.  Nexceris has developed a new sensing technology that rapidly and selectively measures NOx in combustion exhaust streams.',
    link: 'https://nexceris.com/markets/aviation-transportation/',
    points: '547 1497 645 1605 449 1605',
    transform:
      'translate(547.000000, 1551.000000) rotate(-270.000000) translate(-547.000000, -1551.000000)',
  },
  {
    order: '4',
    technology: 'Li-ion Tamer',
    id: 'EV',
    icon: IconLionTamer,
    description:
      'The trends are growing toward electrification of vehicles from urban transportation to personal electric vehicles.  Li-ion Tamer® monitoring devices can provide safety for electric vehicles.',
    link: 'https://nexceris.com/markets/aviation-transportation/',
    points: '2651 1266 2749 1374 2553 1374',
    transform:
      'translate(2651.000000, 1320.000000) rotate(-270.000000) translate(-2651.000000, -1320.000000)',
  },
  {
    order: '5',
    technology: 'Nexceris',
    id: 'Home',
    icon: Logo,
    description:
      'Nexceris works closely with our clients using a proven collaboration method that utilizes your technology and Nexceris’ expertise to allow our partners move to faster to commercialization.',
    link: 'https://nexceris.com/expertise/process/',
  },
  {
    order: '6',
    technology: 'Li-ion Tamer',
    id: 'Solar plus energy storage (battery)',
    icon: IconLionTamer,
    description:
      'Li-ion Tamer® provides unique information about the condition of the battery through monitoring for off-gassing events.',
    link: 'https://nexceris.com/markets/power-energy/',
    points: '2338 1010 2436 1118 2240 1118',
    transform:
      'translate(2338.000000, 1064.000000) rotate(-270.000000) translate(-2338.000000, -1064.000000)',
  },
  {
    order: '7',
    technology: 'Catalyst',
    id: 'Oil and Gas Reforming Sation',
    icon: IconCatalysts,
    description:
      'Chemical manufacturing is entering a new era, with fundamental shifts in chemical production, raw materials and process design.  Nexceris has developed a wide range of proprietary catalysts and process technologies for the application of catalyst coatings.',
    link: 'https://nexceris.com/solutions/catalyst-development/',
    points: '916 839 1014 947 818 947',
    transform:
      'translate(916.000000, 893.000000) rotate(-270.000000) translate(-916.000000, -893.000000)',
  },
  {
    order: '8',
    technology: 'SOEC',
    id: 'Wind plus energy storage (H2)',
    icon: IconSOEC,
    description:
      'To support the growing hydrogen generation market, Nexceris provides expertise and solutions for the development of Solid Oxide Electrolyzer Cells (SOEC).',
    link: 'https://nexceris.com/solutions/solid-oxide-electrolyser-cell/',
    points: '2489 395 2587 503 2391 503',
    transform:
      'translate(2489.000000, 449.000000) rotate(-270.000000) translate(-2489.000000, -449.000000)',
  },
  {
    order: '9',
    technology: 'Materials',
    id: 'Commercial space with Nexceris truck at the loading dock',
    icon: IconMaterials,
    description:
      'Nexceris commercializes innovative ceramic materials technology and products. These technologies and products are suited to the development of Solid Oxide Fuel Cells and fuel processing markets.',
    link: 'https://nexceris.com/expertise/materials/',
    points: '1406 314 1504 422 1308 422',
    transform:
      'translate(1406.000000, 368.000000) rotate(-270.000000) translate(-1406.000000, -368.000000)',
  },
];

export default LANDMARKS;
